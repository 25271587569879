import React, { Fragment } from 'react';
import { IComparisonToolWidgetProps } from './comparison-tool.models';
import { ChevronIcon, Chip, GridItemLayout, GridLayout, useMatchScreenWidth } from '@keplerco/core';
import { RoleComparisonIcon } from '../../../pages/analytics/comparison-tools/role-comparison/role-comparison.icon';
import { PeopleComparisonIcon } from '../../../pages/analytics/comparison-tools/people-comparison/people-comparison.icon';
import classNames from 'classnames';
import styles from './comparison-tool.module.css';

export function ComparisonToolWidget({ title, primaryCondition, secondaryCondition }: IComparisonToolWidgetProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  return (
    <Fragment>
      <div className="card">
        <h3 style={{ marginBottom: 15 }}>{title}</h3>

        <GridLayout columnCount={isMobile ? 1 : 2}>
          <GridItemLayout>
            <button className={classNames('card', styles.conditionButton)} onClick={() => primaryCondition.onClick()}>
              <RoleComparisonIcon tone="baby-blue" />
              <div>
                <h4 className={styles.heading}>
                  {primaryCondition.title}
                  <Chip label={primaryCondition.chipLabel ?? 'Nothing selected'} variant="tiny" backgroundColour={primaryCondition.chipLabel ? 'baby-blue' : 'default'} />
                </h4>

                <small className={classNames(styles.caption)}>{primaryCondition.subtitle}</small>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <ChevronIcon tone="primary" size={25} />
              </div>
            </button>
          </GridItemLayout>

          <GridItemLayout>
            <button className={classNames('card', styles.conditionButton)} disabled={!primaryCondition.chipLabel} onClick={() => secondaryCondition.onClick()}>
              <PeopleComparisonIcon tone={primaryCondition.chipLabel ? 'grape' : 'default'} />
              <div>
                <h4 className={styles.heading}>
                  {secondaryCondition.title}
                  <Chip label={secondaryCondition.chipLabel ?? 'Nothing selected'} variant="tiny" backgroundColour={secondaryCondition.chipLabel ? 'grape' : 'default'} />
                </h4>

                <small className={styles.caption}>{secondaryCondition.subtitle}</small>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <ChevronIcon tone={primaryCondition.chipLabel ? 'primary' : 'default'} size={25} />
              </div>
            </button>
          </GridItemLayout>
        </GridLayout>
      </div>

      {primaryCondition.panel}

      {secondaryCondition.panel}
    </Fragment>
  );
}
