import React from 'react';
import { Chip } from '@keplerco/core';
import styles from './assessment-configuration.module.css';
import classNames from 'classnames';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';

export function AssessmentConfigurationWidget({ isLoading, forcedCompletion, allowReassessment }: { isLoading: boolean; allowReassessment: boolean; forcedCompletion: boolean }): JSX.Element {
  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" height="100%">
      <div className={classNames('card', styles.card)}>
        <div className={styles.chips}>
          <Chip label={allowReassessment ? 'Multiple attempts' : 'Single attempt'} backgroundColour="cards" borderColour="borders" colour="text" borderRadius="20px" variant="tiny" />
          <Chip label={forcedCompletion ? 'Blocked progress' : 'Flexible completion'} backgroundColour="cards" borderColour="borders" colour="text" borderRadius="20px" variant="tiny" />
        </div>

        <div>Configuration</div>
      </div>
    </SkeletonOverlay>
  );
}
