import React, { useEffect, useState } from 'react';
import { AddTwoToneIcon, Anchor, Button, Chip, KebabMenu, MenuItem, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow } from '@keplerco/core';

import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SortField, OrganizationLevelType } from '../../../enums';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import styles from './all-roles.module.css';
import { CompanyRoleListItemResponse, CompanyRoleListResponse } from '../../../models/overmind/company-entity';
export function AllRoles(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [loadingRoles, setLoadingRoles] = useState<string[]>([]);
  const [data, setData] = useState<CompanyRoleListResponse>();

  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 10,
    page: 1,
    organizationLevel: OrganizationLevelType.Company,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });

  async function getRolesData() {
    const response = await actions.getCompanyRoles(request);
    setData(response);
    setLoadingRoles([]);
  }

  useEffect(() => {
    async function initPage() {
      setIsLoading(true);
      await getRolesData();
      setIsLoading(false);
    }
    initPage();
  }, [companyVariables.slug]);

  useEffect(() => {
    if (!data) return;
    getRolesData();
  }, [request]);

  async function handleSearch(search: string) {
    setIsSearching(true);
    setRequest(current => ({ ...current, page: 1, search: search }));
    setIsSearching(false);
  }

  async function handlePageChange(newPage: number) {
    setIsLoading(true);
    setRequest(current => ({ ...current, page: newPage }));
    setIsLoading(false);
  }

  async function activateRole(roleSlug: string | undefined) {
    if (!roleSlug) return;
    setLoadingRoles(current => [...current, roleSlug]);

    await actions.activateCompanyRole({
      companySlug: companyVariables.slug!,
      roleSlug: roleSlug,
      keplerNavigate,
    });

    getRolesData();
  }

  function generateStatusChip(role: CompanyRoleListItemResponse): JSX.Element {
    if (loadingRoles.includes(role.slug!)) {
      return <Chip label="Updating" variant="tiny" backgroundColour="yellow" colour="white" />;
    }

    if (role.active) {
      return <Chip label="Active" variant="tiny" backgroundColour="g" colour="white" />;
    }

    return <Chip label="Draft" variant="tiny" backgroundColour="default" colour="white" />;
  }

  function generateKebabMenuItems(role: CompanyRoleListItemResponse): MenuItem[] {
    return [
      {
        label: 'Activate Role',
        onClick: () => activateRole(role.slug!),
        disabled: role.active,
      },
      {
        label: 'Edit Role',
        onClick: () => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardUpdate.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug!)}`),
      },
    ];
  }

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[
          { name: 'Role & Skill Management', url: PagePath.roleSkillManagementBase },
          { name: 'All Roles', url: window.location.pathname },
        ]}
        title="All roles"
        subtitle="A library of all the roles within your company"
      />
      <div className={styles.companyRolesContainer}>
        <div className={styles.companyRolesActions}>
          <Searchfield loading={isSearching} onInput={handleSearch} />

          <div className={styles.companyRolesActionsGroup}>
            <Button fullWidthMobile type="button" square theme="dark" onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}`)}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Create role
              </div>
            </Button>
          </div>
        </div>

        {isLoading || !!data?.entities.length ? (
          <React.Fragment>
            {isLoading ? (
              <SkeletonLoader height="500px" />
            ) : (
              <Table currentSortBy="role" currentSortDirection={request.sortAscending ? 'Ascending' : 'Descending'} standAloneConfigColumn onSort={() => setRequest(currentState => ({ ...currentState, sortAscending: !currentState.sortAscending }))}>
                {data?.entities.map(role => {
                  return (
                    <TableRow
                      isLoading={loadingRoles.includes(role.slug!)}
                      key={role.slug}
                      id={role.slug!}
                      configCell={() => {
                        return <KebabMenu items={generateKebabMenuItems(role)} />;
                      }}
                    >
                      <TableColumn sortable id="role" label="Role">
                        <Anchor hovertype="opacity" textTransform="none" onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardUpdate.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug!)}`)}>
                          {role.name}
                        </Anchor>
                      </TableColumn>

                      <TableColumn id="status" label="Status">
                        {generateStatusChip(role)}
                      </TableColumn>

                      <TableColumn id="description" label="Description">
                        <div className={styles.descriptionColumn}>
                          {role.description}
                        </div>
                      </TableColumn>


                    </TableRow>
                  );
                })}
              </Table>
            )}
            {!!data && data.totalPages > 1 && (
              <PagerConnector defaultPageNumber={request.page} pageCount={data?.totalPages ?? 0} onPageChange={handlePageChange}>
                {connector => <Pager {...connector} isLoading={isLoading} />}
              </PagerConnector>
            )}
          </React.Fragment>
        ) : (
          <EmptyState title="No roles found" />
        )}
      </div>
    </div>
  );
}
