import { Chip, ListLayout, scoreLevelRAGColour, search, SkillLevel } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { ListItem } from '../../../../../components/lists/list-item';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { useAppState } from '../../../../../overmind';

export function RoleSkillsWidget({
  learnerSlug,
  loading,
  query,
  roleSkills,
  setRoleSkillsCount,
  selectedSkills,
  setSelectedSkills,
}: {
  learnerSlug: string | undefined;
  loading: boolean;
  query: string;
  roleSkills: CompanySkillListItemResponse[];
  setRoleSkillsCount: (roleSkillsCount: number) => void;
  selectedSkills: CompanySkillListItemResponse[];
  setSelectedSkills: (selectedSkills: CompanySkillListItemResponse[]) => void;
}): JSX.Element {
  const { companyVariables } = useAppState();

  const [searchedRoleSkills, setSearchedRoleSkills] = useState<CompanySkillListItemResponse[]>(roleSkills);

  useEffect(() => {
    const nextSearchedRoleSkills = search(roleSkills ?? [], query, 'name');
    setSearchedRoleSkills(nextSearchedRoleSkills);
    setRoleSkillsCount(nextSearchedRoleSkills.length);
  }, [query, roleSkills]);

  if (loading) return <Fragment />;

  if (!searchedRoleSkills?.length) return <EmptyState />;

  return (
    <div style={{ maxHeight: 400, padding: 1, paddingRight: 10, overflowY: 'scroll' }}>
      <ListLayout>
        {searchedRoleSkills.map(roleSkill => {
          const selectedSkill = selectedSkills.some(selectedSkill => selectedSkill.slug === roleSkill.slug);
          return (
            <ListItem
              key={roleSkill.slug}
              selected={selectedSkill}
              onClick={() => {
                let nextSelectedSkills: CompanySkillListItemResponse[] = structuredClone(selectedSkills);
                selectedSkill ? (nextSelectedSkills = nextSelectedSkills.filter(selectedSkill => selectedSkill.slug !== roleSkill.slug)) : nextSelectedSkills.push(roleSkill);
                setSelectedSkills(nextSelectedSkills);
              }}
            >
              <div style={{ display: 'grid', gridTemplateColumns: `1fr ${!!learnerSlug ? '140px' : ''} auto`, gap: 15, alignItems: 'center' }}>
                <div>
                  <div className="body" style={{ color: 'var(--accent-2)' }}>
                    {roleSkill.name}
                  </div>

                  <small>{roleSkill.description ?? 'No description provided'}</small>
                </div>

                {!!learnerSlug && (
                  <SkillLevel
                    level={roleSkill.level ?? companyVariables.minLevel}
                    minLevel={companyVariables.minLevel}
                    maxLevel={companyVariables.maxLevel}
                    dotColour={scoreLevelRAGColour(roleSkill.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                    noLevel={!roleSkill.level}
                    title="Proficiency"
                  />
                )}

                <div>
                  <Chip label="Role skill" backgroundColour="grape" />
                </div>
              </div>
            </ListItem>
          );
        })}
      </ListLayout>
    </div>
  );
}
