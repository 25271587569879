import React from 'react';
import { Questionnaire } from '../../../models/questionnaire';
import { ChevronIcon, Chip, ChipWrapper, colourString, kebabCase } from '@keplerco/core';
import styles from './questionnaire.module.css';
import KeplerNavlink from '../../../navigation/guards/kepler-navlink';
import { PagePath } from '../../../navigation/navigation.enums';
import classNames from 'classnames';
import { CircleCheckTwoToneIcon } from '../../../design/icons/circle-check-two-tone.icon';

export function QuestionnaireCard(props: Questionnaire & { index: number }): JSX.Element {
  const isComplete = props.totalAnsweredQuestions >= props.totalQuestions;
  const hasEarned = props.keplerPointsEarned > 0;

  return isComplete ? (
    <div className={classNames('card action-card', styles.card)}>
      <div className="action-card-content">
        <ChipWrapper>
          <Chip label={`${props.totalQuestions} Skills`} variant="tiny" colour="text" />

          {!isComplete && hasEarned && <Chip label="In Progress" variant="tiny" backgroundColour="light" colour="contrast-text" />}

          {isComplete && <Chip label="Completed" variant="tiny" backgroundColour={'apple'} />}
        </ChipWrapper>

        <h3 id={`${kebabCase(props.skillSubTypeName)}-questionnaire`} style={{ paddingTop: 5 }}>
          {props.skillSubTypeName ?? `Questionnaire #${props.index + 1}`}
        </h3>

        <p style={{ color: colourString('text') }}>{props.skillSubTypeDescription}</p>
      </div>

      <CircleCheckTwoToneIcon size={40} />

      <div className={styles.progressBarContainer}>
        <progress className={styles.progressBar} value={props.totalAnsweredQuestions} max={props.totalQuestions} />
      </div>
    </div>
  ) : (
    <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisBehaviouralQuestionnaire.replace(':questionnaireSlug', props.slug)}`} className={classNames('card action-card interactive', styles.card)}>
      <div className="action-card-content">
        <ChipWrapper>
          <Chip label={`${props.totalQuestions} Skills`} variant="tiny" colour="text" />

          {!isComplete && hasEarned && <Chip label="In Progress" variant="tiny" backgroundColour="light" colour="contrast-text" />}

          {isComplete && <Chip label="Completed" variant="tiny" backgroundColour={'apple'} />}
        </ChipWrapper>

        <h3 id={`${kebabCase(props.skillSubTypeName)}-questionnaire`} style={{ paddingTop: 5 }}>
          {props.skillSubTypeName ?? `Questionnaire #${props.index + 1}`}
        </h3>

        <p style={{ color: colourString('text') }}>{props.skillSubTypeDescription}</p>
      </div>

      <ChevronIcon tone="secondary" strokeWidth={1} size={40} />

      <div className={styles.progressBarContainer}>
        <progress className={styles.progressBar} value={props.totalAnsweredQuestions} max={props.totalQuestions} />
      </div>
    </KeplerNavlink>
  );
}
