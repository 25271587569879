import { Anchor, Pager, SearchBar, Table, TableColumn, TableRow } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { useAppState, useAppActions } from '../../../overmind';
import { generateUniqueSkillNames, transformAverageScores, transformScores } from './competency-matrix.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { DataPoint } from '../../../components/charts/recharts.models';
import { AssessmentSearchParams, PermissionSearchParams, FilteringSearchParams, SortingSearchParams, PagingSearchParams, SearchingSearchParams } from '../../../models/overmind/search-params';
import { ICompetencyMatrixWidgetProps } from './competency-matrix.models';
import { AnalyticsFilters, DEFAULT_FILTERING_SEARCH_PARAMS } from '../../../pages/analytics/analytics-filters/analytics-filters';
import { AnalyticsSortBy } from '../../../pages/analytics/analytics-filters/analytics-sort-by';
import { SORT_BY_ITEMS } from '../average-score-skill/average-score-skill.widget';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import classNames from 'classnames';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

export function CompetencyMatrixWidget({ baseSearchParams, departmentItems, teamItems, roleItems, skillItems, countryItems }: ICompetencyMatrixWidgetProps): React.ReactElement {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortBy: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 10,
  });
  const [filteringSearchParams, setFilteringSearchParams] = useState<FilteringSearchParams>(DEFAULT_FILTERING_SEARCH_PARAMS);
  const [scoresData, setScoresData] = useState<DataPoint[]>([]);
  const [averageScoresData, setAverageScoresData] = useState<DataPoint[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  async function getData(requestParams: AssessmentSearchParams & PermissionSearchParams & SearchingSearchParams & FilteringSearchParams & SortingSearchParams & PagingSearchParams) {
    const scores = await actions.getAssessmentCompetencyScores(requestParams);
    const averageScores = await actions.getAssessmentAverageScores({ ...requestParams, page: 1, pageSize: 9999 });
    const overallAverageScore = await actions.getAssessmentAverageSkill(requestParams);

    const uniqueSkillNames = generateUniqueSkillNames(scores?.people);

    setScoresData(transformScores(scores?.people, uniqueSkillNames, companyVariables.useLevels));
    setAverageScoresData(transformAverageScores(averageScores?.averages, overallAverageScore, uniqueSkillNames, companyVariables.useLevels));
    setTotalPages(scores?.totalPages ?? 0);
  }

  useEffect(() => {
    async function initialise() {
      setIsLoading(true);
      await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
      setIsLoading(false);
    }

    initialise();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading}>
      <WidgetCard title="Competency matrix" subtitle="A view of competencies across the assessed skills, providing insights into capability distribution and potential skill gaps within the assessment participants.">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
          <SearchBar
            label="Search"
            loading={isSearching}
            onInput={async event => {
              setIsSearching(true);
              const search = (event.target as HTMLInputElement).value;
              setSearchParams({ ...searchParams, search, page: 1 });
              await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, search, page: 1 });
              setIsSearching(false);
            }}
          />

          <AnalyticsFilters
            teamItems={teamItems}
            departmentItems={departmentItems}
            roleItems={roleItems}
            skillItems={skillItems}
            countryItems={countryItems}
            filteringSearchParams={filteringSearchParams}
            setFilteringSearchParams={setFilteringSearchParams}
            applyFilters={async () => {
              setIsLoading(true);
              await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
              setIsLoading(false);
            }}
            clearFilters={async () => {
              setIsLoading(true);
              setFilteringSearchParams(DEFAULT_FILTERING_SEARCH_PARAMS);
              await getData({ ...baseSearchParams, ...searchParams, ...DEFAULT_FILTERING_SEARCH_PARAMS });
              setIsLoading(false);
            }}
          />

          <AnalyticsSortBy
            items={SORT_BY_ITEMS}
            value={searchParams.sortBy}
            onChange={event => {
              const sortBy = event.target.value;
              setSearchParams({ ...searchParams, sortBy });
              getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, sortBy });
            }}
          />
        </div>

        <Table
          emptyState={<EmptyState title="Hang tight!" subtitle="We are waiting for data." />}
          footerContent={
            <div className={classNames({ invisible: !totalPages || totalPages < 2 })}>
              <Pager
                activePageNumber={searchParams.page ?? 1}
                pageCount={totalPages}
                onPageChange={async page => {
                  if (page === searchParams.page) return;
                  setIsLoading(true);
                  setSearchParams({ ...searchParams, page });
                  await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, page });
                  setIsLoading(false);
                }}
              />
            </div>
          }
        >
          {/* TODO: shouldn't it also be [] if no averageScoresData? */}
          {(!!scoresData ? [...scoresData, ...averageScoresData] : []).map((score, index) => (
            <TableRow key={score.name} id={score.name!}>
              {Object.entries(score)
                .filter(([key]) => key !== 'slug')
                .map(([key, value]) => (
                  <TableColumn
                    key={score.slug}
                    id={key}
                    locked={key === 'name'}
                    label={key === 'name' ? 'Name' : key}
                    heatmap={{
                      score: typeof value === 'number' ? Number(value) : String(value),
                      min: companyVariables.useLevels ? companyVariables.minLevel : 0,
                      max: companyVariables.useLevels ? companyVariables.maxLevel : 100,
                    }}
                    fontWeight={index === scoresData?.length ? '700' : 'inherit'}
                    width={220}
                  >
                    {!!score.slug && index !== scoresData?.length && key === 'name' ? (
                      <Anchor
                        hovertype="opacity"
                        textTransform="none"
                        onClick={event => {
                          event.stopPropagation();
                          const path = PagePath.analyticsPerson.replace(':companySlug', String(companyVariables.slug)).replace(':personSlug', String(score.slug));
                          keplerNavigate(`${PagePath.analyticsBase}${path}`);
                        }}
                      >
                        {value}
                      </Anchor>
                    ) : (
                      value
                    )}
                  </TableColumn>
                ))}
            </TableRow>
          ))}
        </Table>
      </WidgetCard>
    </SkeletonOverlay>
  );
}
