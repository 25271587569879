import { useEffect } from 'react';
import React from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { ScoresSearchParams } from '../../../models/overmind/search-params';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import styles from './ranked-entities.module.css';
import { ScoreComparison } from '../../../enums/score-comparison';
import { transformScore, transformScores } from './ranked-entities.helpers';
import { DataPoint } from '../../../components/charts/recharts.models';
import { IRankedEntitiesWidgetProps } from './ranked-entities.models';
import { RankedPerformanceChart } from '../../../components/charts/ranked-performance-chart/ranked-performance-chart';

export default function RankedEntitiesWidget({ entity, byScoreType = false, title, rankingLabel }: IRankedEntitiesWidgetProps): JSX.Element {
  const { companyVariables, dateRange } = useAppState();
  const actions = useAppActions();

  const [highestScores, setHighestScores] = React.useState<DataPoint[]>([]);
  const [lowestScores, setLowestScores] = React.useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      if (!entity) {
        setIsLoading(false);
        return;
      }

      const slug = entity.entity?.slug;

      if (byScoreType) {
        await getDataByScoreType();
      } else {
        await getDataByOrganizationLevel(slug, entity.organizationLevel);
      }

      setIsLoading(false);
    }

    async function getDataByOrganizationLevel(slug: string | undefined, organizationLevel: OrganizationLevelType) {
      const startDate = dateRange?.from?.toJSON();
      const endDate = dateRange?.to?.toJSON();
      const searchParams: ScoresSearchParams = {
        sortAscending: undefined,
        page: 1,
        pageSize: 5,
        startDate,
        endDate,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: ScoreType.SkillType,
        scoreTypeSlugs: ['Overall'],
        scoreComparison: undefined,
      };

      if (organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = slug;
      if (organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = slug;
      if (organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = slug;

      const rankedResults = await actions.analyticsGetRankedScoresByOrganizationLevel(searchParams);

      setHighestScores(transformScores(rankedResults?.highest ?? [], companyVariables));
      setLowestScores(transformScores(rankedResults?.lowest ?? [], companyVariables));
    }

    async function getDataByScoreType() {
      const searchParams: ScoresSearchParams = {
        sortAscending: undefined,
        page: 1,
        pageSize: 5,
        startDate: undefined,
        endDate: undefined,
        organizationLevel: entity?.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        scoreType: ScoreType.Role,
        scoreTypeSlugs: undefined,
        scoreComparison: ScoreComparison.RoleExpectedProficiency,
      };

      const rankedResults = await actions.analyticsGetRankedScoresByScoreType(searchParams);

      setHighestScores(transformScore(rankedResults?.highest ?? [], companyVariables));
      setLowestScores(transformScore(rankedResults?.lowest ?? [], companyVariables));
    }

    getData();
  }, [entity, dateRange, companyVariables.slug]);

  return isLoading ? (
    <SkeletonLoader height="450px" />
  ) : (
    <div className="card">
      {!!title && <h3 style={{ marginBottom: 30 }}>{title}</h3>}
      <div className={styles.widgetContainer}>
        <RankedPerformanceChart title={`Highest ranking ${rankingLabel}`} dataPoints={highestScores} fill='baby-blue' />
        <RankedPerformanceChart title={`Lowest ranking ${rankingLabel}`} dataPoints={lowestScores} fill='orange' />
      </div>
    </div>
  );
}


