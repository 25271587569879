import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../../../overmind';
import { ListLayout, ListItemLayout, Chip, Pager, SearchBar, DropdownSelectItem, DropdownMenu } from '@keplerco/core';
import classNames from 'classnames';
import styles from './assessment-progress.module.css';
import { FilteringSearchParams, PagingSearchParams, SearchingSearchParams, SortingSearchParams, UsersListSearchParams } from '../../../../../models/overmind/search-params';
import { SkillAssessmentUser } from '../../../../../models/skill-assessment-config';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { AssessmentProgressLayoutProps } from './assessment-progress.models';
import { CompletionStatus } from '../../../../../enums';
import { AnalyticsFilters, DEFAULT_FILTERING_SEARCH_PARAMS } from '../../../analytics-filters/analytics-filters';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';
import { AnalyticsSortBy } from '../../../analytics-filters/analytics-sort-by';
import { SortByMenuOptions } from '../../../../../enums/sort-by-menu';
import { WidgetCard } from '../../../analytics-filters/widget-card';

// TODO: create enum for label
const SORT_BY_ITEMS: DropdownSelectItem[] = [
  {
    value: SortByMenuOptions.AlphabeticalAtoZ,
    label: 'A to Z',
  },
  {
    value: SortByMenuOptions.AlphabeticalZtoA,
    label: 'Z to A',
  },
  {
    value: SortByMenuOptions.RecentlyCompleted,
    label: 'Recently completed',
  },
];

const STATUS_ITEMS: DropdownSelectItem[] = [
  { value: String(CompletionStatus.NotStarted), label: 'Not Started' },
  { value: String(CompletionStatus.InProgress), label: 'In Progress' },
  { value: String(CompletionStatus.Completed), label: 'Completed' },
];

type SearchParams = SearchingSearchParams &
  SortingSearchParams &
  PagingSearchParams & {
    completionStatus: CompletionStatus[] | undefined;
  };

function generateChipProps(status: CompletionStatus): { label: string; colour: string } {
  switch (status) {
    case CompletionStatus.NotStarted:
      return { label: 'Not Started', colour: 'default' };
    case CompletionStatus.InProgress:
      return { label: 'In Progress', colour: 'baby-blue' };
    case CompletionStatus.Completed:
      return { label: 'Completed', colour: 'apple' };
    default:
      return { label: 'Not Started', colour: 'default' };
  }
}

function getSortByValue(sortBy: string | undefined, sortAscending: boolean | undefined): string | undefined {
  if (sortBy === '0' && sortAscending) return SortByMenuOptions.AlphabeticalAtoZ;
  if (sortBy === '0' && !sortAscending) return SortByMenuOptions.AlphabeticalZtoA;
  if (sortBy === '1') return SortByMenuOptions.RecentlyCompleted;
  return undefined;
}

export function AssessmentProgressLayout({ baseSearchParams, departmentItems, teamItems, roleItems, countryItems }: AssessmentProgressLayoutProps): JSX.Element {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: undefined,
    sortBy: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 15,
    completionStatus: undefined,
  });
  const [filteringSearchParams, setFilteringSearchParams] = useState<FilteringSearchParams>(DEFAULT_FILTERING_SEARCH_PARAMS);
  const [users, setUsers] = useState<SkillAssessmentUser[]>();
  const [totalPages, setTotalPages] = useState<number>(0);

  async function getData(params: UsersListSearchParams) {
    const response = await actions.getSkillAssessmentUsersList({ assessmentSlug: baseSearchParams.assessmentSlug, searchParams: params });
    setUsers(response?.list);
    setTotalPages(response?.totalPages ?? 0);
  }

  useEffect(() => {
    async function initialise() {
      setIsLoading(true);
      await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
      setIsLoading(false);
    }

    initialise();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%">
      <WidgetCard title="Assessment participants">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
          <SearchBar
            label="Search"
            loading={isSearching}
            onInput={async event => {
              const search = (event.target as HTMLInputElement).value;
              setIsSearching(true);
              setSearchParams({ ...baseSearchParams, ...searchParams, search, page: 1 });
              await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, search, page: 1 });
              setIsSearching(false);
            }}
          />

          <AnalyticsFilters
            teamItems={teamItems}
            departmentItems={departmentItems}
            roleItems={roleItems}
            countryItems={countryItems}
            filteringSearchParams={filteringSearchParams}
            setFilteringSearchParams={setFilteringSearchParams}
            applyFilters={async () => {
              setIsLoading(true);
              await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams });
              setIsLoading(false);
            }}
            clearFilters={async () => {
              setIsLoading(true);
              setSearchParams({ ...searchParams, completionStatus: undefined });
              setFilteringSearchParams(DEFAULT_FILTERING_SEARCH_PARAMS);
              await getData({ ...baseSearchParams, ...searchParams, ...DEFAULT_FILTERING_SEARCH_PARAMS, completionStatus: undefined });
              setIsLoading(false);
            }}
          >
            <DropdownMenu
              label="Status"
              items={STATUS_ITEMS}
              value={searchParams.completionStatus?.toString()}
              multiple
              onChange={event => {
                const value = event.target.value;
                setSearchParams({
                  ...searchParams,
                  // eslint-disable-next-line
                  // @ts-ignore
                  completionStatus: value?.includes(',') ? value.split(',') : value,
                });
              }}
            />
          </AnalyticsFilters>

          <AnalyticsSortBy
            items={SORT_BY_ITEMS}
            value={getSortByValue(searchParams.sortBy, searchParams.sortAscending)}
            onChange={async event => {
              setIsLoading(true);
              const value = event.target.value;
              let sortBy;
              let sortAscending;
              if (value === SortByMenuOptions.AlphabeticalAtoZ) {
                sortBy = '0';
                sortAscending = true;
              } else if (value === SortByMenuOptions.AlphabeticalZtoA) {
                sortBy = '0';
                sortAscending = false;
              } else if (value === SortByMenuOptions.RecentlyCompleted) {
                sortBy = '1';
                sortAscending = false;
              }
              setSearchParams({ ...searchParams, sortBy, sortAscending });
              await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, sortBy, sortAscending });
              setIsLoading(false);
            }}
          />
        </div>

        {!users?.length ? (
          <EmptyState title="Hang tight!" subtitle="We are waiting for data." />
        ) : (
          <React.Fragment>
            <ListLayout>
              {users.map(user => {
                const status = generateChipProps(user.completionStatus);
                return (
                  <ListItemLayout key={user.learnerSlug}>
                    <div className={classNames('card', styles.card)}>
                      <div>
                        <div>{user.firstName + ' ' + user.lastName}</div>

                        <small style={{ color: 'var(--text_1)' }}>
                          {user.departmentName || 'No department'} | {user.teamName || 'No team'}
                        </small>
                      </div>

                      <div>{user.role || 'No role'}</div>

                      <div>{user.email}</div>

                      <div>
                        <Chip label={status.label} backgroundColour={status.colour} borderColour={status.colour} borderRadius="20px" variant="tiny" />
                      </div>
                    </div>
                  </ListItemLayout>
                );
              })}
            </ListLayout>

            <div className={classNames({ invisible: !totalPages || totalPages < 2 })}>
              <Pager
                activePageNumber={searchParams.page ?? 1}
                pageCount={totalPages}
                onPageChange={async page => {
                  if (page === searchParams.page) return;
                  setIsLoading(true);
                  setSearchParams({ ...searchParams, page });
                  await getData({ ...baseSearchParams, ...searchParams, ...filteringSearchParams, page });
                  setIsLoading(false);
                }}
              />
            </div>
          </React.Fragment>
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
