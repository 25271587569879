import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { Chip, ListItemLayout, ListLayout } from '@keplerco/core';
import classNames from 'classnames';
import styles from './assessment-included-fop.module.css';
import { AssessmentIncludedFOPResponse } from '../../../models/overmind/assessment-analytics';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

export function AssessmentIncludedFOPWidget({ assessmentSlug }: { assessmentSlug: string }): JSX.Element {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fieldsOfPractice, setFieldsOfPractice] = useState<AssessmentIncludedFOPResponse[]>();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const response = await actions.getAssessmentIncludedFOP(assessmentSlug);
      setFieldsOfPractice(response);
      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  return (
    <SkeletonOverlay isLoading={isLoading}>
      <WidgetCard title="Included fields of practice">
        {!!fieldsOfPractice?.length ? (
          <div className={styles.scrollContainer}>
            <ListLayout>
              {fieldsOfPractice.map(fieldOfPractice => (
                <ListItemLayout key={fieldOfPractice.name}>
                  <div className={classNames('card', styles.card)}>
                    <h4 style={{ gridArea: 'name' }}>{fieldOfPractice.name}</h4>
                    <div className={styles.chip}>
                      {fieldOfPractice.includeJunior && <Chip label="Junior" backgroundColour="cards" borderColour="baby-blue" colour="text" borderRadius="20px" variant="tiny" />}
                      {fieldOfPractice.includeMid && <Chip label="Mid-level" backgroundColour="cards" borderColour="baby-blue" colour="text" borderRadius="20px" variant="tiny" />}
                      {fieldOfPractice.includeSenior && <Chip label="Senior" backgroundColour="cards" borderColour="baby-blue" colour="text" borderRadius="20px" variant="tiny" />}
                    </div>
                  </div>
                </ListItemLayout>
              ))}
            </ListLayout>
          </div>
        ) : (
          <EmptyState />
        )}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
