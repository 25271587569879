import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { UniqueEntity } from '../../../models';
import { ActionCard, PageHeader } from '@keplerco/core';
import { SkillsBreakdownWidget, DailyActivityWidget, LeaderboardWidget } from '../../../widgets';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { EntityAverageWidget } from '../../../components/general/average/entity-average/entity-average.widget';
import { HeatmapWidget } from '../../../widgets/analytics/heatmap/heatmap.widget';
import RankedEntitiesWidget from '../../../widgets/analytics/ranked-entities/ranked-entities.widget';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';
import analyticsStyles from '../analytics.module.css';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export function AnalyticsOverviewPage(): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [activeAssessmentList, setActiveSkillAssessmentList] = useState<UniqueEntity[]>();

  const keplerNavigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const tempActiveAssessmentList = await actions.getActiveSkillAssessmentList(companyVariables.slug!);
      setActiveSkillAssessmentList(tempActiveAssessmentList);
    }

    getData();
  }, [companyVariables.slug]);

  return (
    <div className={classNames('pageWrapper wrapper', analyticsStyles.page)}>
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Overview', url: window.location.pathname },
        ]}
        title="Overview"
        type="actions"
        divider
      >
        <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

          <Daterange
            defaultValue={dateRangeOutput}
            onDaterangeChange={result => {
              actions.setGlobalDateRange(result);
            }}
          />
        </div>
      </PageHeader>

      {!!activeAssessmentList && activeAssessmentList.length > 0 && (
        <ActionCard
          icon="/assessments-pending.svg"
          title="Assessments in progress"
          description="Your team has outstanding assessments"
          action={{
            type: `anchor`,
            text: `View Progress`,
            onClick: () => keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsAssessments.replace(':companySlug', companyVariables.slug!)}`),
          }}
        />
      )}

      <div className={analyticsStyles.oneTwoGrid}>
        <EntityAverageWidget entity={entity} path={PagePath.analyticsOverview} />

        <SkillsBreakdownWidget entity={entity} path={PagePath.analyticsOverview} />
      </div>

      <HeatmapWidget entity={entity} />

      <RankedEntitiesWidget rankingLabel="employees" entity={entity} />

      <CourseEngagementWidget entity={entity} isLearner={false} />

      <DailyActivityWidget entity={entity} />

      <div className={analyticsStyles.twoOneGrid}>
        <LeaderboardWidget entity={entity} />
      </div>
    </div>
  );
}
