import { Chip, formatDate } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { SkillAssessmentAssignee } from '../../../../../enums';
import { AssessmentType } from '../../../../../enums/assessment-type';
import { SkillAssessment } from '../../../../../models';
import { AssessmentOverviewResponse } from '../../../../../models/overmind/assessment-analytics';
import { AssessmentSearchParams, PermissionSearchParams, FilteringSearchParams } from '../../../../../models/overmind/search-params';
import { useAppActions } from '../../../../../overmind';
import { AssessmentConfigurationWidget } from '../assessment-configuration/assessment-configuration.widget';
import { AssessmentIncludedFOPWidget } from '../../../../../widgets/analytics/included-fop/assessment-included-fop.widget';
import { ParticipantsWidget } from '../../../../../widgets/analytics/participants/participants.widget';
import { AssessmentIncludedQuestionnairesWidget } from '../../../../../widgets/analytics/questionnaires/assessment-included-questionnaires.widget';
import { EntitiesAssignedWidget } from '../entities-assigned/entities-assigned.widget';
import SkillsAssignedWidget from '../../../skills-assigned/skills-assigned.widget';
import AssessmentCompletionWidget from '../../../assessment-completion/assessment-completion.widget';
import { AssessmentTypeWidget } from '../../assessment-type/assessment-type.widget';
import styles from './assessment-details.module.css';
import { SkeletonOverlay } from '../../../../../components/general/loading-state/skeleton-overlay';

const DEFAULT_SEARCH_PARAMS: FilteringSearchParams = {
  departmentSlugs: undefined,
  teamSlugs: undefined,
  learnerSlugs: undefined,
  roleSlugs: undefined,
  skillSlugs: undefined,
  countryIds: undefined,
};

export function AssessmentDetailsLayout({ assessment, baseSearchParams }: { assessment: SkillAssessment; baseSearchParams: AssessmentSearchParams & PermissionSearchParams }): JSX.Element {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [overviewData, setOverviewData] = useState<AssessmentOverviewResponse>();

  useEffect(() => {
    async function getData() {
      if (!baseSearchParams) return;
      setIsLoading(true);
      const response = await actions.getAssessmentOverview({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS });
      setOverviewData(response);
      setIsLoading(false);
    }

    getData();
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading} width="100%" minHeight="100vh">
      {!assessment || !overviewData ? (
        <EmptyState title="No available data" />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24, width: '100%' }}>
          <div className={styles.overview}>
            <h4 style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
              Assessment overview
              {assessment.isActive && <Chip label="Active" backgroundColour="baby-blue" borderColour="baby-blue" borderRadius="20px" variant="tiny" />}
              {assessment.isComplete && <Chip label="Closed" backgroundColour="apple" borderColour="apple" borderRadius="20px" variant="tiny" />}
            </h4>

            <div style={{ display: 'flex', gap: 12 }}>
              <small>Activated: {formatDate(new Date(overviewData.activatedDate))}</small>
              {assessment.isComplete && <small>Completed: {formatDate(new Date(overviewData.closedDate))}</small>}
            </div>
          </div>

          <div className="row" style={{ width: '100%', alignItems: 'stretch', gap: 24 }}>
            <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
              <AssessmentTypeWidget isLoading={isLoading} assessmentSlug={assessment.slug} assessmentType={assessment?.assessmentType} />
            </div>

            <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
              <SkillsAssignedWidget isLoading={isLoading} skillCount={overviewData.totalSkills} />
            </div>

            <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
              <AssessmentConfigurationWidget isLoading={isLoading} allowReassessment={overviewData.allowReassessment} forcedCompletion={overviewData.forced} />
            </div>

            <div className="col-fill col-mobile-12" style={{ display: 'flex' }}>
              <EntitiesAssignedWidget isLoading={isLoading} entityType={assessment.assignementType} totalEntities={overviewData.assessmentGroupCount} totalPeople={overviewData.userCount} />
            </div>
          </div>

          <div className={styles.completionParticipants} style={{ gridTemplateColumns: (assessment.assignementType === SkillAssessmentAssignee.Department || assessment.assignementType === SkillAssessmentAssignee.Team) && overviewData.assessmentGroupCount > 1 ? '1fr 1fr' : '1fr' }}>
            <AssessmentCompletionWidget baseSearchParams={baseSearchParams} />

            {(assessment.assignementType === SkillAssessmentAssignee.Department || assessment.assignementType === SkillAssessmentAssignee.Team) && overviewData.assessmentGroupCount > 1 && <ParticipantsWidget baseSearchParams={baseSearchParams} />}
          </div>

          {assessment.assessmentType === AssessmentType.Questionnaire && <AssessmentIncludedQuestionnairesWidget assessmentSlug={assessment.slug} />}

          {assessment.assessmentType === AssessmentType.FieldsOfPractice && <AssessmentIncludedFOPWidget assessmentSlug={assessment.slug} />}
        </div>
      )}
    </SkeletonOverlay>
  );
}
