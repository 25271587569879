import React, { useEffect, useState } from 'react';
import { useAppActions } from '../../../overmind';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { DataPoint } from '../../../components/charts/recharts.models';
import { HorizontalBarChart } from '../../../components/charts/horizontal-bar-chart/horizontal-bar-chart';
import { calculateDomainMax, transformToDataPoints } from './pathways-seniority.helpers';
import { IPathwaysSeniorityWidgetProps } from './pathways-seniority.models';
import { SkeletonOverlay } from '../../../components/general/loading-state/skeleton-overlay';
import { AnalyticsFilters, DEFAULT_FILTERING_SEARCH_PARAMS, MAX_PAGE_SIZE } from '../../../pages/analytics/analytics-filters/analytics-filters';
import { AssessmentSearchParams, FilteringSearchParams, PagingSearchParams, PermissionSearchParams, SearchingSearchParams } from '../../../models/overmind/search-params';
import { WidgetCard } from '../../../pages/analytics/analytics-filters/widget-card';

const DEFAULT_SEARCH_PARAMS: SearchingSearchParams & PagingSearchParams = {
  search: undefined,
  page: 1,
  pageSize: MAX_PAGE_SIZE,
};

export default function PathwaysSeniorityWidget({ baseSearchParams, departmentItems, teamItems, countryItems }: IPathwaysSeniorityWidgetProps): JSX.Element {
  const actions = useAppActions();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filteringSearchParams, setFilteringSearchParams] = useState<FilteringSearchParams>(DEFAULT_FILTERING_SEARCH_PARAMS);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [domainMax, setDomainMax] = useState<number>(100);

  async function getData(request: AssessmentSearchParams & PermissionSearchParams & SearchingSearchParams & FilteringSearchParams & PagingSearchParams) {
    setIsLoading(true);
    const response = await actions.getAssessmentFOPPathways(request);
    setDataPoints(response ? transformToDataPoints(response) : []);
    setDomainMax(calculateDomainMax(response || []));
    setIsLoading(false);
  }

  useEffect(() => {
    getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...filteringSearchParams });
  }, [baseSearchParams]);

  return (
    <SkeletonOverlay isLoading={isLoading}>
      <WidgetCard title="Pathways and Seniority" subtitle="A summary of the most popular pathways and the seniorities selected for each.">
        <AnalyticsFilters
          teamItems={teamItems}
          departmentItems={departmentItems}
          countryItems={countryItems}
          filteringSearchParams={filteringSearchParams}
          setFilteringSearchParams={setFilteringSearchParams}
          applyFilters={() => {
            getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...filteringSearchParams });
          }}
          clearFilters={() => {
            setFilteringSearchParams(DEFAULT_FILTERING_SEARCH_PARAMS);
            getData({ ...baseSearchParams, ...DEFAULT_SEARCH_PARAMS, ...DEFAULT_FILTERING_SEARCH_PARAMS });
          }}
        />

        {!dataPoints?.length ? <EmptyState title="Hang tight!" subtitle="We are waiting for data." /> : <HorizontalBarChart xLabel="Number of participants" domain={[0, domainMax]} fill={['baby-blue', 'grape', 'bright-pink']} dataPoints={dataPoints} stacked showLegend />}
      </WidgetCard>
    </SkeletonOverlay>
  );
}
