/* eslint-disable indent */
import { Anchor, CascadingPanel, CascadingPanels, ChevronIcon, Chip, IChip, ListItemLayout, ListLayout, Toggle, useCascadingPanelsConnector, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../../../../../components/lists/list-item';
import classNames from 'classnames';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { ImportSkillPanel } from '../../../../administration/roles/manage-role/import-skill.panel';
import { SelectSkillsLayout } from './select-skills.layout';
import { useAppActions } from '../../../../../overmind';
import { StepSkeleton } from '../../../../../components/general/stepper/step/step.skeleton';
import { CascadingFocusPanelIds, ErrorMessage, ISkillsStepLayoutProps } from './skills-step.models';
import styles from './skills-step.module.css';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import layoutStyles from '../../../../../design/layout.module.css';

export function SkillsStepLayout({ assessmentSlug, isLoading, setIsLoading, preferences, people, setPeople, roleSlug, onBack, onNext }: ISkillsStepLayoutProps): JSX.Element {
  const actions = useAppActions();

  const [selectedPeople, setSelectedPeople] = useState<IPerson[]>();
  const [selectedPerson, setSelectedPerson] = useState<IPerson>();
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [selectedSkills, setSelectedSkills] = useState<CompanySkillListItemResponse[]>([]);
  const [sync, setSync] = useState<string>(crypto.randomUUID());
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const isMobile = useMatchScreenWidth('mobile');

  const { openPanelIds, next, previous } = useCascadingPanelsConnector();

  useEffect(() => {
    setSelectedPeople(people);
  }, [people]);

  useEffect(() => {
    if (!errorMessage) return;
    generateErrorMessage(selectedPeople);
  }, [preferences, selectedPeople, applyToAll, selectedSkills]);

  function generateErrorMessage(people: IPerson[] | undefined): ErrorMessage | undefined {
    let errorMessage = undefined;
    if (!people) return undefined;

    if (applyToAll) {
      errorMessage = !selectedSkills.length ? ErrorMessage.NoSkills : undefined;
      setErrorMessage(errorMessage);
      return errorMessage;
    }

    if (preferences.allowManagerReview) {
      if (people.some(person => !person.manager && !person.skills.length)) {
        errorMessage = ErrorMessage.ManagerReviewSkills;
      }
    }

    if (preferences.allowPeerReview) {
      if (people.some(person => !person.skills.length)) {
        errorMessage = ErrorMessage.PeerReviewSkills;
      }
    }

    if (preferences.allowSelfReview) {
      if (people.some(person => !person.skills.length)) {
        errorMessage = ErrorMessage.SelfReviewSkills;
      }
    }

    setErrorMessage(errorMessage);
    return errorMessage;
  }

  function generateChips(person: IPerson): IChip[] {
    let chips: IChip[] = [
      {
        label: 'Manager',
        backgroundColour: 'baby-blue',
      },
      {
        label: `${applyToAll ? selectedSkills.length : person.skills.length} skill(s)`,
        backgroundColour: applyToAll ? 'default' : 'grape',
      },
    ];

    if (!person.manager || (!preferences.allowManagerReview && !preferences.allowPeerReview)) chips = chips.filter(chip => !chip.label.includes('Manager'));
    if (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager) chips = chips.filter(chip => !chip.label.includes('skill'));

    return chips;
  }

  if (isLoading) return <StepSkeleton />;

  return (
    <React.Fragment>
      <EntitiesCardWidget title="Skills" description="Select skills for the assignment">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <ListItem onClick={!applyToAll ? undefined : () => next(CascadingFocusPanelIds.SelectSkills)}>
            <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: `auto ${isMobile ? '1fr' : 'auto 1fr auto'} auto` }}>
              <div onClick={event => event.stopPropagation()}>
                <Toggle id="applyToAll" name="applyToAll" toggleForegroundColour="white" value={applyToAll} onChange={() => setApplyToAll(!applyToAll)} />
              </div>

              {isMobile ? (
                <div>
                  <h5>Apply same skills to all</h5>

                  <p style={{ color: 'var(--text_1)' }}>Choose particular skills and apply it to the group of people</p>

                  <div style={{ marginTop: 4 }}>
                    <Chip label={`${selectedSkills.length} skill(s)`} backgroundColour={!applyToAll ? 'default' : 'grape'} />
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <h5>Apply same skills to all</h5>

                  <p style={{ color: 'var(--text_1)' }}>Choose particular skills and apply it to the group of people</p>

                  <div>
                    <Chip label={`${selectedSkills.length} skill(s)`} backgroundColour={!applyToAll ? 'default' : 'grape'} />
                  </div>
                </React.Fragment>
              )}

              <ChevronIcon tone={!applyToAll ? 'default' : 'primary'} />
            </div>
          </ListItem>

          <div>
            <h4>Assigned people & skills</h4>

            <div style={{ marginTop: 12, height: 400, overflowY: 'auto' }}>
              <ListLayout>
                {selectedPeople?.map(person => (
                  <ListItemLayout
                    key={person.id}
                    disabled={applyToAll || (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager)}
                    onClick={() => {
                      setSelectedPerson(person);
                      next(CascadingFocusPanelIds.SelectSkills);
                    }}
                  >
                    <div className={classNames('card', styles.listItem)} style={{ gridTemplateColumns: isMobile ? '1fr auto' : '1fr 1fr 195px auto' }}>
                      {isMobile ? (
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
                          <div className="body" style={{ color: 'var(--accent-2)' }}>
                            {person.firstName} {person.lastName}
                          </div>

                          <small>
                            {person.department.name} | {person.team.name}
                          </small>

                          <small>{person.email}</small>

                          <div style={{ marginTop: 12, display: 'flex', gap: 12 }}>
                            {generateChips(person).map(chip => (
                              <Chip key={chip.label} {...chip} />
                            ))}
                          </div>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div>
                            <div className="body" style={{ color: 'var(--accent-2)' }}>
                              {person.firstName} {person.lastName}
                            </div>

                            <small>
                              {person.department.name} | {person.team.name}
                            </small>
                          </div>

                          <small>{person.email}</small>

                          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
                            {generateChips(person).map(chip => (
                              <Chip key={chip.label} {...chip} />
                            ))}
                          </div>
                        </React.Fragment>
                      )}

                      <ChevronIcon tone={applyToAll || (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager) ? 'default' : 'primary'} />
                    </div>
                  </ListItemLayout>
                ))}
              </ListLayout>
            </div>
          </div>
        </div>

        <div className={classNames('formErrorMessage', { invisible: !errorMessage })}>{errorMessage}&nbsp;</div>
      </EntitiesCardWidget>

      <footer className={classNames('card', layoutStyles.wizardFooter)}>
        <Anchor arrow reverse onClick={onBack}>
          Back
        </Anchor>

        <Anchor
          arrow
          onClick={async () => {
            if (!selectedPeople) return;

            const tempSelectedPeople: IPerson[] = structuredClone(selectedPeople);
            if (applyToAll) tempSelectedPeople.forEach(personToEdit => (personToEdit.skills = selectedSkills));

            if (!!generateErrorMessage(tempSelectedPeople)) return;

            setIsLoading(true);

            // TODO: refactor APIs to simplify FE code
            const peopleAdded: IPerson[] = tempSelectedPeople.filter(tempPersonToEdit => !people?.some(person => person.id === tempPersonToEdit.id));
            const peopleRemoved: IPerson[] = [];
            const peopleUpdated: IPerson[] = [];
            people?.forEach(person => (!tempSelectedPeople.some(tempPersonToEdit => tempPersonToEdit.id === person.id) ? peopleRemoved.push(person) : peopleUpdated.push(person)));

            const skillsToAdd: { skillId: number; userId: string }[] = [];
            const skillsToRemove: { skillId: number; userId: string }[] = [];

            peopleAdded.forEach(personAdded => {
              personAdded.skills.forEach(skill => skillsToAdd.push({ skillId: skill.companyEntityId!, userId: personAdded.id }));
            });
            peopleRemoved.forEach(personRemoved => {
              personRemoved.skills.forEach(skill => skillsToRemove.push({ skillId: skill.companyEntityId!, userId: personRemoved.id }));
            });
            peopleUpdated.forEach(initialPerson => {
              const currentPerson = tempSelectedPeople.find(person => person.id === initialPerson.id);
              if (!currentPerson) return;

              // remove skills on the initial person that aren't on the current person
              initialPerson.skills.forEach(initialSkill => {
                if (!currentPerson.skills.some(currentSkill => currentSkill.companyEntityId === initialSkill.companyEntityId)) {
                  skillsToRemove.push({ skillId: initialSkill.companyEntityId!, userId: initialPerson.id });
                }
              });

              // add skills on the current person that aren't on the initial person
              currentPerson.skills.forEach(currentSkill => {
                if (!initialPerson.skills.some(initialSkill => initialSkill.companyEntityId === currentSkill.companyEntityId)) {
                  skillsToAdd.push({ skillId: currentSkill.companyEntityId!, userId: initialPerson.id });
                }
              });
            });

            for (const skillToAdd of skillsToAdd) {
              await actions.addSkillToFocusArea({
                ...skillToAdd,
                assessmentSlug,
              });
            }

            for (const skillToRemove of skillsToRemove) {
              await actions.removeSkillFromFocusArea({
                ...skillToRemove,
                assessmentSlug,
              });
            }

            setPeople(tempSelectedPeople);
            onNext();
            setIsLoading(false);
          }}
        >
          Next
        </Anchor>
      </footer>

      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={id => {
          previous();
          switch (id) {
            case CascadingFocusPanelIds.SelectSkills: {
              setSelectedPerson(undefined);
              break;
            }

            case CascadingFocusPanelIds.ImportSkills: {
              setSync(crypto.randomUUID());
              break;
            }
          }
        }}
      >
        <CascadingPanel id={CascadingFocusPanelIds.SelectSkills}>
          <SelectSkillsLayout
            roleSlugs={!!roleSlug ? [roleSlug] : selectedPerson?.roleSlugs}
            personToEdit={selectedPerson}
            skills={selectedPerson?.skills ?? selectedSkills}
            sync={sync}
            onBack={() => {
              previous();
              setSelectedPerson(undefined);
            }}
            onAssign={selectedSkills => {
              !!selectedPerson
                ? setSelectedPeople(currentState => {
                    if (!currentState) return;
                    const nextState: IPerson[] = structuredClone(currentState);
                    const nextPerson = nextState.find(person => person.id === selectedPerson.id);
                    if (!!nextPerson) nextPerson.skills = selectedSkills;
                    return nextState;
                  })
                : setSelectedSkills(selectedSkills);
              previous();
              setSelectedPerson(undefined);
            }}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.ImportSkills}>
          <ImportSkillPanel
            supertitle="Assessments"
            onClose={() => {
              previous();
              setSync(crypto.randomUUID());
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    </React.Fragment>
  );
}
