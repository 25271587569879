import { Context } from '../..';
import { RankedScoresResponse } from '../../../models/overmind/analytics';
import { SkillAssessment, UniqueEntity } from '../../../models';
import {
  AssessmentAnalyticsSearchParams,
  AssessmentAverageScoresResponse,
  AssessmentCompetencyScoreResponse,
  AssessmentHighestLowestResponse,
  AssessmentIncludedFOPResponse,
  AssessmentOverviewResponse,
  AssessmentParticipantsResponse,
  AssessmentQuestionnairesResponse,
  AssessmentSummaryResponse,
  AssessmentTotalCompletionsResponse,
  VersusScoreResponse,
} from '../../../models/overmind/assessment-analytics';
import { AssessmentSearchParams, FilteringSearchParams, PagingSearchParams, PermissionSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../models/overmind/search-params';
import { Score } from '../../../models/view/score';
import * as base from '../base';

const CONTROLLER = `assessment/analytics`;

export async function assessmentTotalCompletions(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams): Promise<AssessmentTotalCompletionsResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `completions${base.params(payload)}`) };
  const response: base.IResponse<AssessmentTotalCompletionsResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentAverageSkill(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams): Promise<Score | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `average${base.params(payload)}`) };
  const response: base.IResponse<Score> = await base.request(request);
  return response.data;
}

export async function getAssessmentPerformanceOverview(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams & PagingSearchParams): Promise<RankedScoresResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `ranked${base.params(payload)}`) };
  const response: base.IResponse<RankedScoresResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentHighestAndLowestSkill(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams): Promise<AssessmentHighestLowestResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `highlow${base.params(payload)}`) };
  const response: base.IResponse<AssessmentHighestLowestResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentParticipants(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams): Promise<AssessmentParticipantsResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `participants${base.params(payload)}`) };
  const response: base.IResponse<AssessmentParticipantsResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentOverview(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams): Promise<AssessmentOverviewResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `overview${base.params(payload)}`) };
  const response: base.IResponse<AssessmentOverviewResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentIncludedFOP({ state }: Context, assessmentSlug: string): Promise<AssessmentIncludedFOPResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, 'fop', `fields${base.params({ assessmentSlug, companySlug: state.companyVariables.slug })}`) };
  const response: base.IResponse<AssessmentIncludedFOPResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentFOPPathways(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & SearchingSearchParams & FilteringSearchParams & PagingSearchParams): Promise<AssessmentParticipantsResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, 'fop', `pathways${base.params(payload)}`) };
  const response: base.IResponse<AssessmentParticipantsResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentCompetencyScores(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams & SortingSearchParams & PagingSearchParams): Promise<AssessmentCompetencyScoreResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `competency${base.params(payload)}`) };
  const response: base.IResponse<AssessmentCompetencyScoreResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentAverageScores(context: Context, payload: AssessmentSearchParams & PermissionSearchParams & FilteringSearchParams & SortingSearchParams & PagingSearchParams): Promise<AssessmentAverageScoresResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `averages${base.params(payload)}`) };
  const response: base.IResponse<AssessmentAverageScoresResponse> = await base.request(request);
  return response.data;
}

export async function getAssessmentIncludedQuestionnaires({ state }: Context, assessmentSlug: string): Promise<AssessmentQuestionnairesResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `questionnaires${base.params({ assessmentSlug, companySlug: state.companyVariables.slug })}`) };
  const response: base.IResponse<AssessmentQuestionnairesResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessments(context: Context, payload: AssessmentAnalyticsSearchParams) {
  const request: base.IRequest = { url: base.apiURL(`assessments${base.params(payload)}`) };
  const response: base.IResponse<AssessmentSummaryResponse> = await base.request(request);
  return response.data;
}

export async function getAssessment(context: Context, payload: { assessmentSlug: string; companySlug: string }) {
  const request: base.IRequest = { url: base.apiURL('assessments', 'get-assessment', payload.companySlug, payload.assessmentSlug) }; // TODO: different controller, why is this here?
  const response: base.IResponse<SkillAssessment> = await base.request(request);
  return response.data;
}

// refactored
export async function getAssessmentAnalyticsScoreByYear(context: Context, payload: AssessmentSearchParams & PermissionSearchParams): Promise<VersusScoreResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `peer`, `score-by-year${base.params(payload)}`) };
  const response: base.IResponse<VersusScoreResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentAnalyticsScoreByFrequency(context: Context, payload: AssessmentSearchParams & PermissionSearchParams): Promise<VersusScoreResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `peer`, `score-by-frequency${base.params(payload)}`) };
  const response: base.IResponse<VersusScoreResponse[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentAnalyticsDepartments(context: Context, payload: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, payload, 'departments') };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentAnalyticsTeams(context: Context, payload: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, payload, 'teams') };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentAnalyticsRoles(context: Context, payload: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, payload, 'roles') };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);
  return response.data;
}

export async function getAssessmentAnalyticsSkills(context: Context, payload: string): Promise<UniqueEntity[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, payload, 'skills') };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);
  return response.data;
}
