import React, { Fragment, useEffect, useState } from 'react';
import { Anchor, Button, CascadingPanel, CascadingPanels, Chip, colourString, KebabMenu, ListItemLayout, ListLayout, useCascadingPanelsConnector, useMatchScreenWidth } from '@keplerco/core';
import styles from './people-step.module.css';
import classNames from 'classnames';
import { CompanyRoleAssignee, IPeopleStepLayoutProps } from './people-step.models';
import { SelectPeopleLayout } from './select-people/select-people.layout';
import { useAppActions } from '../../../../../overmind';
import { StepSkeleton } from '../../../../../components/general/stepper/step/step.skeleton';
import { SelectCohortLayout } from '../../../../../components/panels/select-cohort/select-cohort.layout';
import { OrganizationLevelType } from '../../../../../enums';
import SearchRoleIcon from '../../../../../design/icons/search-role';
import { CohortType } from '../../../../../components/panels/select-cohort/select-cohort.models';
import { UniqueEntity } from '../../../../../models';

enum CascadingFocusPanelIds {
  SelectCohort = 'SelectCohort',
  SelectPeople = 'SelectPeople',
}

export function PeopleStepLayout({ roleSlug, assignedPeople, parentIsLoading, completeStep, goToPreviousStep }: IPeopleStepLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { openPanelIds, next, previous, closeAll } = useCascadingPanelsConnector();

  const [selectedCohortType, setSelectedCohortType] = useState<CohortType>(CohortType.Department);
  const [selectedEntity, setSelectedEntity] = useState<UniqueEntity>();
  const [selectedPeople, setSelectedPeople] = useState<CompanyRoleAssignee[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    setSelectedPeople(assignedPeople);
  }, [assignedPeople]);

  async function handleNext() {
    if (!roleSlug) return;

    setIsLoading(true);

    const peopleToAssign = selectedPeople?.filter(person => !assignedPeople?.some(assigned => assigned.entitySlug === person.entitySlug));

    if (peopleToAssign?.length) {
      await actions.assignCompanyRole({
        entitySlug: roleSlug,
        assignees: [...peopleToAssign.map(person => ({ entitySlug: person.entitySlug, organizationLevel: OrganizationLevelType.Learner, name: person.name }))],
      });
    }

    const peopleToUnassign = assignedPeople?.filter(person => !selectedPeople?.some(selected => selected.entitySlug === person.entitySlug));

    if (peopleToUnassign?.length) {
      await actions.unassignCompanyRole({
        entitySlug: roleSlug,
        assignees: [...peopleToUnassign.map(person => ({ entitySlug: person.entitySlug, organizationLevel: OrganizationLevelType.Learner, name: person.name }))],
      });
    }

    setIsLoading(false);
    completeStep();
  }

  if (parentIsLoading || isLoading) return <StepSkeleton />;

  return (
    <Fragment>
      <div className={classNames('card', styles.container)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>People</h3>
          <p style={{ color: colourString('text_1') }}>Manage the team members in this role.</p>
        </div>

        <div className="card action-card">
          <SearchRoleIcon />

          <div className="action-card-content">
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <h3>Assign people</h3>
              {!!selectedPeople?.length && <Chip label={selectedPeople.length === 1 ? `1 person` : `${selectedPeople.length} people`} variant="tiny" borderColour="g" backgroundColour="g" colour="black" />}
            </div>

            <p>Assign this role to the right people in your organisation.</p>
          </div>

          <Button type="button" filled arrow={false} onClick={() => next(CascadingFocusPanelIds.SelectCohort)}>
            Assign
          </Button>
        </div>

        {!!selectedPeople?.length && (
          <Fragment>
            <h4>Assigned people</h4>

            <div style={{ maxHeight: 400, overflowY: 'auto' }}>
              <ListLayout>
                {selectedPeople?.map((person, index) => (
                  <ListItemLayout key={index}>
                    {isMobile ? (
                      <div
                        className="card"
                        style={{
                          display: 'grid',
                          gap: 15,
                          alignItems: 'center',
                          gridTemplateColumns: '30px 1fr',
                        }}
                      >
                        <KebabMenu
                          items={[
                            {
                              label: 'Remove person',
                              onClick: () => setSelectedPeople(selectedPeople.filter(p => p.entitySlug !== person.entitySlug)),
                            },
                          ]}
                        />
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                            <div className="body" style={{ color: 'var(--accent-2)' }}>
                              {person.name}
                            </div>

                            {!!person.department && !!person.team && (
                              <small>
                                {person.department} | {person.team}
                              </small>
                            )}

                            <small>{person.email}</small>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card"
                        style={{
                          display: 'grid',
                          gap: 15,
                          columnGap: 10,
                          alignItems: 'center',
                          gridTemplateColumns: `30px 1fr 1fr`,
                        }}
                      >
                        <KebabMenu
                          items={[
                            {
                              label: 'Remove person',
                              onClick: () => setSelectedPeople(selectedPeople.filter(p => p.entitySlug !== person.entitySlug)),
                            },
                          ]}
                        />
                        <div>
                          <div className="body" style={{ color: 'var(--accent-2)' }}>
                            {person.name}
                          </div>

                          {!!person.department && !!person.team && (
                            <small>
                              {person.department} | {person.team}
                            </small>
                          )}
                        </div>

                        <small>{person.email}</small>
                      </div>
                    )}
                  </ListItemLayout>
                ))}
              </ListLayout>
            </div>
          </Fragment>
        )}
      </div>

      <footer className="card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={goToPreviousStep}>
          Back
        </Anchor>

        <Anchor arrow onClick={handleNext}>
          Next
        </Anchor>
      </footer>

      <CascadingPanels openPanelIds={openPanelIds} onClosePanel={previous}>
        <CascadingPanel id={CascadingFocusPanelIds.SelectCohort}>
          <SelectCohortLayout
            supertitle="Roles Wizard"
            title="Who do you want to assign?"
            subtitle="Select the people that you want to assign to this role."
            cohortTypes={[CohortType.Department, CohortType.Team, CohortType.People]}
            selectedCohortType={selectedCohortType}
            setSelectedCohortType={setSelectedCohortType}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            onBack={previous}
            onNext={() => next(CascadingFocusPanelIds.SelectPeople)}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.SelectPeople}>
          <SelectPeopleLayout
            selectedCohortType={selectedCohortType}
            selectedEntity={selectedEntity}
            selectedPeople={selectedPeople ?? []}
            onBack={previous}
            onDone={people => {
              setSelectedPeople(people);
              closeAll();
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    </Fragment>
  );
}
