import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor, Button, KeplerPoints, ListItemLayout, ProgressBar, SkillLevel, SkillPercentage } from '@keplerco/core';
import classNames from 'classnames';
import styles from './skill-list-item.module.css';
import { useAppState } from '../../../../overmind';
import { UrgencyIndicationChip } from '../../../your-skills/urgency-indication.chip';
import { ISkillListItemProps } from './skill-list-item.models';
import { getSubdomain } from '../../../../library/helpers/get-subdomain';
import { comparisonScoreTitle } from '../../../../library/helpers/comparison-score-title';
import { ScoreComparison } from '../../../../enums/score-comparison';

export function SkillListItem(props: ISkillListItemProps): JSX.Element {
  const subdomain = getSubdomain();

  const { companyVariables } = useAppState();

  const navigate = useNavigate();

  const isMobile = window.innerWidth < 768;

  return (
    <ListItemLayout>
      <div className={classNames('card', [styles.content])} style={{ gridTemplateColumns: !!props.urgencyIndication || !!props.totalActivityCount ? (isMobile ? '1fr' : 'minmax(0px, 1fr) minmax(0px, 1fr) 180px') : isMobile ? '1fr' : 'minmax(0px, 1fr) 180px' }}>
        <div className={styles.info}>
          <h4 className={styles.heading}>{props.name}</h4>

          {!props.urgencyIndication && props.urgencyIndication !== 0 && (
            <div className={styles.scores}>
              {companyVariables.useLevels ? (
                <SkillLevel level={props.learnerScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!props.learnerScore} title="Your proficiency" />
              ) : (
                <SkillPercentage percentage={props.learnerScore?.percentage ?? 0} noPercentage={!props.learnerScore} title="Your proficiency" />
              )}

              {companyVariables.scoreComparison !== ScoreComparison.None && (
                <Fragment>
                  {companyVariables.useLevels ? (
                    <SkillLevel level={props.comparisonScore?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!props.comparisonScore} noLevelText="Not captured" title={comparisonScoreTitle(companyVariables.scoreComparison)} />
                  ) : (
                    <SkillPercentage percentage={props.comparisonScore?.percentage ?? 0} noPercentage={!props.comparisonScore} noPercentageText="Not captured" title={comparisonScoreTitle(companyVariables.scoreComparison)} />
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>

        {!props.urgencyIndication && props.urgencyIndication !== 0 ? (
          <div className={styles.progressIndication} style={{ display: !!props.totalActivityCount ? 'flex' : 'none' }}>
            {!!props.totalActivityCount && <ProgressBar value={props.completedActivityCount} max={props.totalActivityCount} theme="secondary" round />}

            <div className={styles.captions}>
              {!!props.totalActivityCount && (
                <small>
                  {props.completedActivityCount} of {props.totalActivityCount} activities completed
                </small>
              )}

              {!!props.keplerPointsAvailable && (
                <small style={{ marginLeft: !props.totalActivityCount ? 'auto' : 'unset' }}>
                  <KeplerPoints trimWhiteSpace forceFontWeight="bold" points={props.completedActivityCount === props.totalActivityCount ? props.keplerPointsEarned : props.keplerPointsAvailable} />
                  {!!props.totalActivityCount && props.completedActivityCount === props.totalActivityCount ? ' Earned' : ' Available'}
                </small>
              )}
            </div>
          </div>
        ) : (
          <div className="chipWrapper">
            <UrgencyIndicationChip urgencyIndication={props.urgencyIndication} />
          </div>
        )}

        <div className={styles.actions}>
          {!!props.totalActivityCount && props.completedActivityCount < props.totalActivityCount ? (
            <Button type="button" arrow onClick={() => navigate(props.path)}>
              {!!props.completedActivityCount ? 'Continue' : 'Start'}
            </Button>
          ) : (
            <Anchor style={{ padding: '8px 0' }} onClick={() => navigate(props.path)}>
              {subdomain.includes('roche-deep') ? 'Completed' : 'View Skill'}
            </Anchor>
          )}
        </div>
      </div>
    </ListItemLayout>
  );
}
