import React, { useEffect, useReducer, useState } from 'react';
import { SortField } from '../../../enums/sort-field';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { CourseMappingStatus } from '../../../enums/course-mapping-status';
import * as store from './course-mapping.store';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { PagePath } from '../../../navigation/navigation.enums';
import { CourseMappingsListItem, CourseMappingsSearchParams } from '../../../models/overmind/learning-management';
import { courseMappingStatuses, learningPlatformTypes } from '../learning-management.helpers';
import { Chip, MenuItem, DropdownSelectItem, PageHeader, Searchfield, Filters, DropdownSelect, Table, Pager, TableRow, KebabMenu, TableColumn, Anchor, Button, Modal } from '@keplerco/core';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import learningManagementStyles from '../learning-management.module.css';
import { ConfirmationModalLayout } from '../../../widgets/layouts';

export function CourseMappingPage(): JSX.Element {
  const actions = useAppActions();

  const { companyVariables, permissions, user } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const keplerNavigate = useKeplerNavigate();

  const [removeCourseSlug, setRemoveCourseSlug] = useState('');
  async function fetchData(searchParams: CourseMappingsSearchParams) {
    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: true });

    const data = await actions.getCourseMappings(searchParams);
    dispatch({ type: store.CourseMappingActionTypes.SetData, payload: data });

    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: false });
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.learningManagementCourseMapping, type: FetchType.PageFetching });

      if (!permissions) return;

      const company = await actions.getCompany(companyVariables.slug!);
      dispatch({ type: store.CourseMappingActionTypes.SetHeader, payload: { entityName: company?.companyName, companySlug: companyVariables.slug! } });

      const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement.organizationLevels);
      const request: CourseMappingsSearchParams = { ...state.searchParams, organizationLevel: organizationLevel?.organizationLevel, companySlug: companyVariables.slug };
      dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: request });

      await fetchData(request);

      actions.stopLoader(PagePath.learningManagementCourseMapping);
    }

    if (!!permissions) initPage();
  }, [permissions, companyVariables.slug]);

  useEffect(() => {
    if (!state.data) return;
    fetchData(state.searchParams);
  }, [state.searchParams]);

  async function onInputHandler(value: string) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, search: value, page: 1 } });
  }

  async function onSortHandler(sortField: SortField, sortAscending: boolean) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, sortField, sortAscending } });
  }

  function generateStatusChip(mapping: CourseMappingsListItem): JSX.Element {
    if (mapping.learningObjectMappingStatus === CourseMappingStatus.Mapped) return <Chip label="Mapped" backgroundColour="g" variant="tiny" />;

    return <Chip label="Not mapped" variant="tiny" />;
  }

  function onPageChangeHandler(page: number) {
    if (page === state.searchParams?.page) return;
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page } });
  }

  function generateKebabMenuItems(learningObject: CourseMappingsListItem): MenuItem[] {
    const isMapped = learningObject.learningObjectMappingStatus === CourseMappingStatus.Mapped;

    const systemAdminKebabs = user !== undefined && user.isSystemAdmin ? [
      {
        label: 'Edit course',
        onClick: () =>
          keplerNavigate(
            `/learning-management/${companyVariables.slug!}/course/${learningObject.slug}`
          ),
      },
      {
        label: 'Remove course',
        onClick: () => {
          setRemoveCourseSlug(learningObject.slug);
        }
      }
    ] : [];

    return [
      {
        label: isMapped ? 'Edit mapping' : 'Create mapping',
        onClick: () =>
          keplerNavigate(
            `${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse
              .replace(':companySlug', companyVariables.slug!)
              .replace(':courseSlug', learningObject.slug)}`
          ),
      }, ...systemAdminKebabs
    ];
  }


  // filters
  function applyCourseMappingStatusItem(value: string) {
    setSelectedCourseMappingStatus(value);
  }
  function applyLearningPlatformTypeItem(value: string) {
    setSelectedLearningPlatform(value);
  }

  const [selectedCourseMappingStatus, setSelectedCourseMappingStatus] = useState<string>();
  const [courseMappingStatusItems] = useState<DropdownSelectItem[]>(courseMappingStatuses?.map(item => ({ ...item, onClick: () => applyCourseMappingStatusItem(item.value) })) ?? []);
  const [selectedLearningPlatform, setSelectedLearningPlatform] = useState<string>();
  const [learningPlatformItems] = useState<DropdownSelectItem[]>(learningPlatformTypes?.map(item => ({ ...item, onClick: () => applyLearningPlatformTypeItem(item.value) })) ?? []);

  function applyFilters() {
    dispatch({
      type: store.CourseMappingActionTypes.SetRequest,
      payload: {
        ...state.searchParams,
        page: 1,
        courseMappingStatus: !selectedCourseMappingStatus ? undefined : parseInt(selectedCourseMappingStatus),
        learningPlatformType: !selectedLearningPlatform ? undefined : parseInt(selectedLearningPlatform),
      },
    });
  }

  function clearFilters() {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page: 1, courseMappingStatus: undefined, learningPlatformType: undefined } });
    setSelectedCourseMappingStatus(undefined);
    setSelectedLearningPlatform(undefined);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningManagementCourseMapping}>
        <div className="wrapper stack">
          <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} subtitle="Control which courses are mapped to each skill" divider />

          <div className={learningManagementStyles.actions}>
            <Searchfield loading={!!state.searchParams.search && state.loading} onInput={onInputHandler} />

            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect id="status" name="Status" label="Status" value={selectedCourseMappingStatus} items={courseMappingStatusItems} />

              <DropdownSelect id="learningPlatform" name="Learning Platform" label="Learning Platform" value={selectedLearningPlatform} items={learningPlatformItems} />
            </Filters>

            {user !== undefined &&
              user.isSystemAdmin &&
              <div style={{ display: 'flex', alignSelf: 'end' }}>
                <Button
                  type='button'
                  onClick={() => keplerNavigate(`/learning-management/${companyVariables.slug!}/course/new`)}
                >
                  Create Course
                </Button>
              </div>
            }
          </div>

          {!!state.data && state.loading && <SkeletonLoader height="460px" />}

          {!state.loading && (
            <Table
              onSort={() => onSortHandler(SortField.Name, !state.searchParams.sortAscending)}
              currentSortDirection={state.searchParams.sortAscending ? 'Ascending' : 'Descending'}
              currentSortBy="Name"
              standAloneConfigColumn
              emptyState={<EmptyState title="No course mappings found" />}
              footerContent={
                <PagerConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.searchParams?.page} pageCount={state.data?.totalPages ?? 0}>
                  {connector => {
                    return <Pager {...connector} />;
                  }}
                </PagerConnector>
              }
            >
              {state.data?.learningObjectList.map(course => (
                <TableRow key={course.slug} id={course.slug} configCell={() => <KebabMenu items={generateKebabMenuItems(course)} />}>
                  <TableColumn sortable id="Name" label="Name">
                    <Anchor
                      hovertype="opacity"
                      textTransform="none"
                      onClick={event => {
                        event.stopPropagation();
                        keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse.replace(':companySlug', companyVariables.slug!).replace(':courseSlug', course.slug)}`);
                      }}
                    >
                      <span className={learningManagementStyles.name}>{course.name}</span>
                    </Anchor>
                  </TableColumn>

                  <TableColumn id="contains" label="Contains">
                    {course.activityCount} activities
                  </TableColumn>

                  <TableColumn id="status" label="Status">
                    {generateStatusChip(course)}
                  </TableColumn>
                </TableRow>
              ))}
            </Table>
          )}
        </div>
      </PageLoaderLayer>
      <Modal open={!!removeCourseSlug} onClose={() => setRemoveCourseSlug('')}>
        <ConfirmationModalLayout
          onClickCancel={() => setRemoveCourseSlug('')}
          submitButtonText="Remove"
          onClickSubmit={async () => {
            await actions.deleteCustomCourse({ courseSlug: removeCourseSlug, companySlug: companyVariables.slug! });
            fetchData(state.searchParams);
            setRemoveCourseSlug('');
          }}
          title="Remove Course"
          subtitle="Are you sure you want to remove this course?"
        />
      </Modal>
    </React.Fragment>
  );
}
